import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Title from '../../../../components/panelcontrols/paneltitle'
import { ToastContainer, toast } from 'react-toastify';
import Functions from './function'
import Table from './table'

const Index = () => {
    const {id} = useParams();
    const [product,setProduct] = useState(null);
    const [list,setList] = useState([]); 
    useEffect(()=>{
        Functions.GetProduct(id,setProduct);
        Functions.Get(id,setList)
    },[id])
  return (
    <div>
        {product &&
            <Title Value={product.title}/>
        }
        <Table List={list}/>
        <ToastContainer/>
    </div>
  )
}

export default Index
