import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
  return (
    <div className='admin_layout_rightbar_wrapper'>
        <div className='admin_layout_rightbar_container'>
            <div className='admin_layout_rightbar_logout'>
                <Link to={'/signout'}>
                    <div className='admin_layout_rightbar_logout_container'>
                        <div className='admin_layout_rightbar_logout_lable'>
                            <p>Logout</p>
                        </div>
                        <div className='admin_layout_rightbar_logout_icon'>
                            <FontAwesomeIcon icon={faRightFromBracket}/>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='admin_layout_rightbar_user_container'>
                <div className='admin_layout_rightbar_user_image_wrapper'>
                    <div className='admin_layout_rightbar_user_image_container'>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                </div>
                <div className='admin_layout_rightbar_user_details'>
                    <h4>{User.username}</h4>
                    <p>{User.role}</p>
                </div>
            </div>
            <div className='admin_layout_rightbar_reminders_container'>
                <div className='admin_layout_rightbar_reminders_title'>
                    <p>Reminders</p>
                    <div className='admin_layout_rightbar_reminders_add'>
                        <FontAwesomeIcon icon={faAdd}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index
