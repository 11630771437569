import ApiUrl from "../../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues,toast,toastid) => {
    fetch(ApiUrl.Admin_Pospal_Products,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setTimeout(() => {
            toast.update(toastid, { render: "Loaded", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }, 500);
        setValues(data.response);
    });
}

const POST = (body,setValues,toast,toastid) => {
    fetch(ApiUrl.Admin_Pospal_Products,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        console.log(data);
        if(data.success){
            if(data.lastpage){
                Get(setValues,toast,toastid);
            }
            else{
                POST({id: body.id, parameterValue: data.parameterValue, nextpage: true},setValues,toast,toastid);
            }
        }
        else{
            toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }
    });
}

export default {Get,POST}