import React, { useEffect, useState } from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Table from './table'
import Functions from './function'
import './index.css'
import { ToastContainer, toast } from 'react-toastify';
import Input from '../../../../components/forms/primaryinput'

const Index = () => {
    const [company, setCompany] = useState('');
    const [industry, setIndustry] = useState('');
    const [interfaceval, setInterface] = useState('');
    const [appid, setAppid] = useState('');
    const [appsecret, setAppsecret] = useState('');
    const [list, setList] = useState([]);
    useEffect(()=>{
        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
        Functions.Get(setList,toast,toastid);
    },[])
  return (
    <div>
        <Title Value={'POSPAL Warehouses'}/>
        <div className='airiosoft_form_wrapper'>
          <div className='airiosoft_form_container'>
            <div className='airiosoft_form_row'>
              <div className='airiosoft_form_col33'>
                <Input Lable={'Company'} Placeholder={'Enter company'} Type={'text'} Value={company} setValue={setCompany}/>
              </div>
              <div className='airiosoft_form_col33'>
                <Input Lable={'Industry'} Placeholder={'Enter industry'} Type={'text'} Value={industry} setValue={setIndustry}/>
              </div>
              <div className='airiosoft_form_col33'>
                <Input Lable={'Interface'} Placeholder={'Enter interface'} Type={'text'} Value={interfaceval} setValue={setInterface}/>
              </div>
            </div>
            <div className='airiosoft_form_row'>
              <div className='airiosoft_form_col50'>
                <Input Lable={'App id'} Placeholder={'Enter app id'} Type={'text'} Value={appid} setValue={setAppid}/>
              </div>
              <div className='airiosoft_form_col50'>
                <Input Lable={'App secret'} Placeholder={'Enter app secret'} Type={'text'} Value={appsecret} setValue={setAppsecret}/>
              </div>
            </div>
            <div className='airiosoft_form_row'>
              <div className='airiosoft_form_col33'>
              </div>
              <div className='airiosoft_form_col33'>
                <Button Value={'Save'} Action={()=>{
                  Functions.Post({
                    company: company, 
                    industry: industry, 
                    interfaceval: interfaceval, 
                    appid: appid,
                    appsecret: appsecret
                  },setList,toast)
                }}/>
              </div>
              <div className='airiosoft_form_col33'>
              </div>
            </div>
            
          </div>
        </div>
        <Table List={list}/>
        <ToastContainer/>
    </div>
  )
}

export default Index
