import React, { useEffect, useState } from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import { faShopify } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css'
import { faLink, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import Functions from './function'

const Index = () => {
  const [List, setList] = useState([
    {text: "Shopify products count", icon:faShopify, count: 0},
    {text: "Pospal products count", icon:faWarehouse, count: 0},
    {text: "Connected products count", icon:faLink, count: 0}
  ]);
  useEffect(()=>{
    Functions.Get((values)=>{
      setList([
        {text: "Shopify products count", icon:faShopify, count: values},
        {text: "Pospal products count", icon:faWarehouse, count: 0},
        {text: "Connected products count", icon:faLink, count: 0}
      ]);
    })
  },[])
  return (
    <div>
      <Title Value={'Dashboard'}/>
      <div className='dashboard_box_row'>
        {List.map((item,index)=>{
          return(
            <div className='dashboard_box_col' key={index}>
              <div className='dashboard_box_item'>
                <FontAwesomeIcon icon={item.icon}/>
                <p>{item.text}</p>
                <h3>{item.count}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default Index
