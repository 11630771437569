import React, { useEffect, useState } from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Functions from './function'
import Button from '../../../components/forms/primarybutton'
import { ToastContainer, toast } from 'react-toastify';
import Table from './table'

const Index = () => {
    const [List,setList]= useState([]);
    useEffect(()=>{
      const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
      Functions.Get(setList,toast,toastid);
    },[])
  return (
    <div>
      <Title Value={'Connections'}/>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <div style={{marginRight:"10px", marginBottom:"10px"}}>
              <p>Total : {List.length}</p>
          </div>
          <div style={{marginLeft:"10px", marginBottom:"10px"}}>
          <Button Value={'Hard refresh'} Action={()=>{
              Functions.Post(setList,toast)
          }}/>
          </div>
      </div>
      <Table List={List}/>
      <ToastContainer/>
    </div>
  )
}

export default Index
