import {BrowserRouter, Routes, Route,Navigate, Outlet} from 'react-router-dom';
import './App.css'
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './configTools/scrollTop';
import PublicLayout from './layout/public'
import PublicLogin from './pages/public/login'
import PublicShopifySuccess from './pages/public/shopifysuccess'
import PublicShopifyFailed from './pages/public/shopifyfailed'

import AdminLayout from './layout/admin'
import AdminDashboard from './pages/admin/dashboard'
import AdminShopifyProducts from './pages/admin/shopifyproducts/list'
import AdminShopifyProduct from './pages/admin/shopifyproducts/product'
import AdminPospalWarehouses from './pages/admin/pospal/warehouses'
import AdminPospalCategories from './pages/admin/pospal/categories'
import AdminPospalProducts from './pages/admin/pospal/products'
import AdminConnections from './pages/admin/connections'
import AdminConnectionsShopifyna from './pages/admin/connections/shopifyna'
import AdminConnectionsPospalna from './pages/admin/connections/pospalna'
import AdminPassword from './pages/admin/password'

import Signout from './pages/signout';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<PublicLayout/>}>
            <Route index element={<PublicLogin/>} />
            <Route path='shopifymessagesuccess' element={<PublicShopifySuccess/>}/>
            <Route path='shopifymessagefailed' element={<PublicShopifyFailed/>}/>
          </Route>
          <Route path='/admin' element={<AdminLayout/>}>
            <Route index element={<Navigate to='/admin/dashboard'/>} />
            <Route path='dashboard' element={<AdminDashboard/>}/>
            <Route path='password' element={<AdminPassword/>}/>
            <Route path='shopifyproducts' element={<AdminShopifyProducts/>}/>
            <Route path='shopifyproducts/:id' element = {<AdminShopifyProduct />} />
            <Route path='pospal' element={<Outlet/>}>
              <Route index element={<Navigate to={'/admin/pospal/warehouse'}/>} />
              <Route path='warehouse' element={<AdminPospalWarehouses/>}/>
              <Route path='categories' element={<AdminPospalCategories/>}/>
              <Route path='products' element={<AdminPospalProducts/>}/>
            </Route>
            <Route path='connections' element={<Outlet/>}>
              <Route index element={<AdminConnections/>}/>
              <Route path='shopifypending' element={<AdminConnectionsShopifyna/>}/>
              <Route path='pospending' element={<AdminConnectionsPospalna/>}/>
            </Route>
          </Route>
          <Route path='/signout' element={<Signout/>}/>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
