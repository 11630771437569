const BaseURL = '/api/'//'/api/';//'http://localhost:4000/api/';

const ApiUrl = {
    OAuth_Login: BaseURL+'oauth/login',
    Admin_Shopify_Products: BaseURL+'admin/shopifyproducts',
    Admin_Shopify_Products_Count: BaseURL+'admin/shopifyproducts/product/count',
    Admin_Shopify_Products_Varient: BaseURL+'admin/shopifyproducts/varient',
    Admin_Pospal_Warehouse: BaseURL+'admin/pospal/warehouse',
    Admin_Pospal_Categories: BaseURL+'admin/pospal/categories',
    Admin_Pospal_Products: BaseURL+'admin/pospal/products',
    Admin_Connections: BaseURL+'admin/connections',
    Admin_Users_Password: BaseURL+'admin/users/password'
}

export default ApiUrl