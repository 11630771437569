import React, { useEffect, useState } from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Table from './table'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';
import WarehouseFunctions from '../warehouses/function'

const Index = () => {
    const [list, setList] = useState([]);
    useEffect(()=>{
        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
        Functions.Get(setList,toast,toastid);
    },[])
  return (
    <div>
        <Title Value={'POSPAL Products'}/>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div style={{marginRight:"10px", marginBottom:"10px"}}>
                <p>Total : {list.length}</p>
            </div>
            <div style={{marginLeft:"10px", marginBottom:"10px"}}>
            <Button Value={'Hard refresh'} Action={()=>{
                const toastid = toast.loading("Please wait loading warehouses ...",{ position: toast.POSITION.BOTTOM_CENTER});
                WarehouseFunctions.Get((data)=>{
                    for (let index = 0; index < data.length; index++) {
                        const element = data[index];
                        const toastid = toast.loading(`Please wait loading products from warehouse '${element.company}' ...`,{ position: toast.POSITION.BOTTOM_CENTER});
                        Functions.POST({id:element.id},setList,toast,toastid);
                    }
                },toast,toastid)
            }}/>
            </div>
        </div>
        <Table List={list}/>
        <ToastContainer/>
    </div>
  )
}

export default Index
