import React, {useState} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import { ToastContainer, toast } from 'react-toastify';
import Functions from './function';

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    const [password,setPassword] = useState('');
    const [repassword,setRePassword] = useState('');
    const reset = ()=>{
        setPassword('');
        setRePassword('');
    }
  return (
    <div>
      <Title Value={'Password'}/>
      <div className='password_row'>
        <div className='password_col'>
            <div className='password_form'>
                <Input Lable={'New Password'} Type={'text'} Value={password} setValue={setPassword}/>
                <Input Lable={'Re-Enter Password'} Type={'text'} Value={repassword} setValue={setRePassword}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(password === ''){
                        toast.update(toastid, { render: "Enter a valid password", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                        return;
                    }
                    if(password !== repassword){
                        toast.update(toastid, { render: "Password doesnt match", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                        return;
                    }
                    Functions.PutPassword(User.id,{
                        password: password
                    },toast,toastid,()=>{reset()})
                }}/>
            </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Index
