import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import Image from '../../../../images/2.png'

const Index = () => {
  return (
    <div className='panel_watermark_wrapper'>
        <a href='https://www.airiosoft.com' target='_blank'>
            <div className='panel_watermark_contaier'>
                <p>powered by</p>
                <img src={Image}/>
            </div>
        </a>
    </div>
  )
}

export default Index
