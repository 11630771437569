import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues) => {
    fetch(ApiUrl.Admin_Shopify_Products_Count,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        console.log(data)
        setValues(data.response);
    });
}


export default {Get}