import React from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import './index.css'
import Leftbar from './leftbar'
import Rightbar from './rightbar'

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    if(!User){
        return <Navigate to={"/login"}/>
    }
    else{
        if(User.role !== 'admin'){
            return <Navigate to={"/login"}/>
        }
    }
    return (
        <div className='admin_layout_container'>
            <Leftbar/>
            <div className='admin_layout_body_wrapper'>
                <div className='admin_layout_body_container'>
                    <Outlet/>
                </div>
            </div>
            <Rightbar />
        </div>
    )
}

export default Index
