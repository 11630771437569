import React, { useState } from 'react'
import Input from '../../../components/forms/primaryinput'
import Button from '../../../components/forms/primarybutton'
import './index.css'
import image from '../../../images/1.jpg'
import branding from '../../../images/2.png'
import Functions from './function'

const Form = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
  return (
    <div className='public_login_wrapper'>
      <div className='public_login_container'>
        <div className='public_login_row'>
          <div className='public_login_col50' style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div className='public_login_image'>
              <img src={image} alt="login" />
            </div>
          </div>
          <div className='public_login_col50'>
            <div className='public_login_title'>
                <h3>Login in</h3>
            </div>
            <div className='public_login_form'>
                <Input Lable={''} Placeholder={'Enter username'} Type={'text'} Value={username} setValue={setUsername}/>
                <Input Lable={''} Placeholder={'Enter password'} Type={'password'} Value={password} setValue={setPassword}/>
                <Button Value={'Log in'} Action={()=>{
                    Functions.Submit({
                        username: username,
                        password: password
                    });
                }}/>
            </div>
            <div className='public_login_branding'>
              <a href='https://airiosoft.com' target='_blank'>
                <img src={branding}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form
