import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const GetProduct = (setValues) => {
    fetch(ApiUrl.Admin_Shopify_Products,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            var sendData = [];
            for (let index = 0; index < data.response.length; index++) {
                const element = data.response[index];
                sendData.push({
                    id: element.id,
                    name: element.title
                });
            }
            setValues(sendData);
        }
    });
}

const GetVarients = (id,setValues) => {
    fetch(ApiUrl.Admin_Shopify_Products_Varient+'/'+id,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            var sendData = [];
            for (let index = 0; index < data.response.length; index++) {
                const element = data.response[index];
                sendData.push({
                    id: element.id,
                    name: element.title
                });
            }
            setValues(sendData);
        }
    });
}

const GetPospalCategories = (setValues) => {
    fetch(ApiUrl.Admin_Pospal_Categories,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            var sendData = [];
            for (let index = 0; index < data.response.length; index++) {
                const element = data.response[index];
                sendData.push({
                    id: element.uid,
                    name: element.name
                });
            }
            setValues(sendData);
        }
    });
}

const GetPospalProducts = (id,setValues) => {
    fetch(ApiUrl.Admin_Pospal_Products+"/category/"+id,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            var sendData = [];
            for (let index = 0; index < data.response.length; index++) {
                const element = data.response[index];
                sendData.push({
                    id: element.id,
                    name: element.name+"-"+element.barcode
                });
            }
            setValues(sendData);
        }
    });
}

const Get = (setValues,toast,toastid) => {
    fetch(ApiUrl.Admin_Connections,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        console.log(data)
        setTimeout(() => {
            toast.update(toastid, { render: "Loaded", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }, 500);
        setValues(data.response);
    });
}

const Post = (setValues,toast) => {
    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
    fetch(ApiUrl.Admin_Connections,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            Get(setValues,toast,toastid);
        }
        else{
            toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }
    });
}

const GetShopifyNA = (setValues,toast,toastid) => {
    fetch(ApiUrl.Admin_Connections+"/shopifyna",{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        console.log(data);
        setTimeout(() => {
            toast.update(toastid, { render: "Loaded", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }, 500);
        setValues(data.response);
    });
}

const GetPospalNA = (setValues,toast,toastid) => {
    fetch(ApiUrl.Admin_Connections+"/pospalna",{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        console.log(data);
        setTimeout(() => {
            toast.update(toastid, { render: "Loaded", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
        }, 500);
        setValues(data.response);
    });
}



export default {GetProduct, GetVarients, GetPospalCategories, GetPospalProducts, Get, Post, GetShopifyNA, GetPospalNA}