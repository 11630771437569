import React,{useState,useEffect} from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Index = ({List}) => {
    const [filterList,setfilterList] = useState(List);
    function filterFunction(value){
      if(value === ""){
        setfilterList(List);
      }
      else{
        const filtedList = List.filter((obj)=>{
          return Object.values(obj)
          .join(" ").toLowerCase()
          .includes(value.toLowerCase());
        })
        setfilterList(filtedList);
      }
    }
    useEffect(()=>{
        setfilterList(List);
    },[List])
    return (
        <div className='user_table_wrapper'>
          <div className='user_table_search'>
            <input onChange={(e)=>{filterFunction(e.target.value);}}/>
            <div className='user_table_search_icon'>
              <FontAwesomeIcon icon={faSearch}/>
            </div>
          </div>
          <div className='user_table_container'>
            <div className='_table'>
              <div className='_thead'>
                <div className='_tr'>
                  <div className='_th' style={{width:"20%"}}></div>
                  <div className='_th' style={{width:"30%"}}>Title</div>
                  <div className='_th' style={{width:"30%"}}>SKU</div>
                  <div className='_th' style={{width:"20%", textAlign:"right"}}>Qty</div>
                </div>
              </div>
              <div className='_tbody'>
                {
                  filterList.map((item,index)=>{
                    return(
                        <div className='_tr' key={index}>
                          <div className='_td' style={{width:"20%"}}>
                              <div className='user_table_image' style={{backgroundImage:`url(${item.image})`}}></div>
                          </div>
                          <div className='_td' style={{width:"30%"}}>{item.title}</div>
                          <div className='_td' style={{width:"30%"}}>{item.sku}</div>
                          <div className='_td' style={{width:"20%", textAlign:"right"}}>{item.inventoryquantity}</div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>      
        </div>
    )
}

export default Index
