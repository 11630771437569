import React from 'react'
import { Navigate } from 'react-router-dom'
import Form from './form';

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    if(User){
        if(User.role === 'admin'){
            return <Navigate to={'/admin'}/>
        }
        else{
            return <Navigate to={"/"}/>
        }
    }
    else{
        return (
          <Form/>
        )
    }
}

export default Index
