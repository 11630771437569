import React, { useEffect, useState } from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Table from './table'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [list, setList] = useState([]);
    useEffect(()=>{
        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
        Functions.Get(setList,toast,toastid);
    },[])
  return (
    <div>
        <Title Value={'POSPAL Categories'}/>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div style={{marginRight:"10px", marginBottom:"10px"}}>
                <p>Total : {list.length}</p>
            </div>
            <div style={{marginLeft:"10px", marginBottom:"10px"}}>
            <Button Value={'Hard refresh'} Action={()=>{
                const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                Functions.POST({
                    id: 1001
                },setList,toast,toastid);
            }}/>
            </div>
        </div>
        <Table List={list}/>
        <ToastContainer/>
    </div>
  )
}

export default Index
