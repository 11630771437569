import React from 'react'
import './index.css'
import { faKey, faHouse, faBox,faBug, faMoneyCheckDollar, faLink, faWarehouse} from '@fortawesome/free-solid-svg-icons'
import Item from './item'
import Watermark from './watermark';
import { faShopify } from '@fortawesome/free-brands-svg-icons';

const Index = () => {
    const navList = [
        {name: 'Dashboard', url: '/admin/dashboard', icon:faHouse, slug: 'dashboard', subnav: []},
        {name: 'Shopify', url: '/admin/shopifyproducts', icon:faShopify, slug: 'shopifyproducts', subnav: []},
        {name: 'Pospal', url: '/admin/pospal/warehouse', icon:faWarehouse, slug: 'pospal', subnav: [
          {name: 'Warehouses', url: '/admin/pospal/warehouse', slug: 'pospal/warehouse'},
          {name: 'Categories', url: '/admin/pospal/categories', slug: 'pospal/categories'},
          {name: 'Products', url: '/admin/pospal/products', slug: 'pospal/products'}
        ]},
        {name: 'Connections', url: '/admin/connections', icon:faLink, slug: 'connections', subnav: [
          {name: 'Shopify N/A', url: '/admin/connections/shopifypending', slug: 'connections/shopifypending'},
          {name: 'Pospal N/A', url: '/admin/connections/pospending', slug: 'connections/pospending'}
        ]},
        {name: 'Password', url: '/admin/password', icon:faKey, slug: 'password', subnav: []}
    ]
  return (
    <div className='admin_layout_leftbar_container'>
        <div className='admin_layout_leftbar_logo'></div>
      <div className='admin_layout_leftbar_navigations'>
        {
            navList.map((item,index)=>{
                return(
                    <div key={index}>
                        <Item Item={item}/>
                    </div>
                )
            })
        }
      </div>
      <Watermark />
    </div>
  )
}

export default Index
